a {
  color: var(--brandColor);
  text-decoration: none;
  @extend animate;
}

p {
  margin-top: 0;

  &:nth-child(n+2) {
    margin-top: calc(var(--space) * 0.8);
  }
}

h2 {
  color: var(--brandColor);
  font-size: calc(var(--fontSize) * 1.8);
  line-height: calc(var(--fontSize) * 1.2);
  text-transform: uppercase;
  margin: 0 0 var(--space) 0;
}

h3 {
  color: var(--brandColor);
  font-size: calc(var(--fontSize) * 1.2);
  font-weight: bold;
  margin: 0 0 calc(var(--space) * 0.5) 0;
}

.ul--list {
  margin: 0 0 0 calc(var(--space) * 1.25);
  padding: 0;

  li {
    display: block;
    text-indent: calc(var(--space) * -0.5);

    &:before {
      color: var(--brandColor);
      margin-right: calc(var(--space) * 0.5);
      @mixin fontAwesomeIcon f0da;
    }
  }
}

.title {
  color: var(--brandColor);
  font-size: calc(var(--fontSize) * 1.2);
  font-style: italic;
  font-weight: bold;
  line-height: calc(var(--space) * 1.5);
  padding: var(--space) 0;
  text-align: center;
}

hr {
  border-bottom: none;
  border-top: 1px dashed color(var(--textColor) a(20%));
  margin: calc(var(--space) * 1.5) 0;
}