/* plugins */
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.6.2/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700italic,700,400italic);
@import '../../node_modules/smartmenus/dist/css/sm-core-css.css';
@import '../../node_modules/slick-carousel/slick/slick.css';
@import '../../node_modules/slick-carousel/slick/slick-theme.css';
@import 'animate.css';

/* base */
@import 'base/variables';

/* mixin */
@import 'mixins/selector';
@import 'mixins/extend';
@import 'mixins/mixin';
@import 'mixins/myAnimate';

/* layout */
@import 'layout/base';
@import 'layout/nav';
@import 'layout/pageTitle';
@import 'layout/aside';
@import 'layout/footer';
@import 'layout/page';

/* modules */
@import 'modules/block';
@import 'modules/fontFace';
@import 'modules/form';
@import 'modules/table';
@import 'modules/text';

/* plugins custom */
@import 'plugins/slick';

/* breakpoint */
@import 'mixins/breakpoint';

/* page */
@import 'page/about';
@import 'page/bagged_cement_delivery_to_myanmar';
@import 'page/cement_clinker_trading';
@import 'page/coal_flyash';
@import 'page/contact';
@import 'page/foundation';
@import 'page/gallery';
@import 'page/gypsum';
@import 'page/index';
@import 'page/marketing_strategy';
@import 'page/sand_slag_lime';
@import 'page/sustainable_development';
