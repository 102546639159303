.block {
  clear: fix;
  line-height: var(--space);

  & + & {
    margin-top: calc(var(--space) * 2);
  }

  &__half {
    lost-waffle: 1/2;
  }

  &__onethird {
    lost-waffle: 1/3;
  }

  &__twothird {
    lost-waffle: 2/3;
  }

  &__quarter {
    lost-waffle: 1/4;
  }

  &__onefifth {
    lost-waffle: 1/5;
  }

  &__onefifth--vertical {
    lost-row: 1/5;
  }

  &--color {
    background: var(--brandColor);
    color: var(--bgColor);
    margin-top: calc(var(--space) * -0.3);
    padding: var(--space);
  }

  &--deco {
    background: color(var(--textColor) a(10%));
    min-height: 328px;
    padding: calc(var(--space) * 2) calc(var(--space) * 1.5);
    position: relative;

    /*&:before {
      background: var(--brandColor);
      content: '';
      display: block;
      height: 5px;
      position: absolute;
      width: 100px;
    }

    &:after {
      bottom: 0;
      right: 0;
    }

    &:before {
      left: 0;
      top: 0;
    }*/
  }

}