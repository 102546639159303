.about {

  @mixin pageBanner 439px;

  &__title {
    margin-bottom: calc(var(--space) * 1);

    h2 {
      margin: 0;
      text-align: center;
    }

    p {
      text-align: center;
      margin: calc(var(--space) * 0.3) 0 0;

      &:after {
        background: var(--brandColor);
        content: '';
        display: block;
        height: 5px;
        margin: var(--space) auto calc(var(--space) * 2);
        width: 50px;
      }
    }
  }

  &__text {
    line-height: calc(var(--space) * 1.5);
    margin-top: calc(var(--space) * 0.8);
    word-wrap: break-word;
    hyphens: auto;
  }

  .material {
    background: var(--textColor);
    background-position: 50% 50%;
    background-size: cover;
    border-radius: var(--space);
    height: 130px;
    overflow: hidden;
    position: relative;
    width: 130px;
    @extend animate;

    &:--enter {
      .material__name {
        opacity: 0.8;
      }
    }

    &__name {
      background: var(--textColor);
      border-radius: var(--space);
      bottom: 0;
      color: var(--bgColor);
      left: 0;
      opacity: 0;
      padding-top: 40%;
      position: absolute;
      text-align: center;
      text-shadow: 1px 1px 2px var(--textColor);
      top: 0;
      right: 0;
      z-index: 1;
      @extend animate;
    }
  }

  h3 {
    margin-top: calc(var(--space) * 0.5);
  }

  .block--deco {
    line-height: calc(var(--space) * 1.5);
    font-size: calc(var(--fontSize) * 1.1);
    height: 439px;
  }
}