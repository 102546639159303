.pageTitle {
  background: color(var(--brandColor) a(85%));
  padding: var(--space);
  text-transform: uppercase;

  h1 {
    color: var(--bgColor);
    font-size: calc(var(--fontSize) * 1.8);
    line-height: calc(var(--fontSize) * 2);
    font-weight: bold;
    lost-column: 6/12;
    margin: 0;
    padding: 0;
  }

  .breadcrumb {
    color: var(--brandColorLight);
    lost-column: 6/12;
    text-align: right;

    li {
      display: inline;
      line-height: calc(var(--fontSize) * 1.5);

      & + & {

        &:before {
          padding: 0 calc(var(--space) * 0.5);
          @mixin fontAwesomeIcon f105;
        }
      }
    }

    a {
      color: var(--brandColorLight);
      @extend animate;

      &:--enter {
        color: var(--bgColor);
      }
    }
  }
}