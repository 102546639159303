* {
  box-sizing: border-box;
}

body, html {
  height: 100%;
  width: 1300px;

  @media (--screen1200) {
    width: 100%;
  }
}

body {
  background: url(../images/bg.jpg) no-repeat top center;
  color: var(--textColor);
  font: var(--fontSize) var(--fontFamily);
  margin: 0;
}

img {
  border: none;
  max-width: 100%;
}

:focus {
  outline: 0;
}

.preloader {
  background: var(--bgColor);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2000;

  &__img {
    animation-timing-function: linear;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: rotate360;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;
    width: 80px;
  }
}

.backToTop {
  background: var(--brandColor);
  border-radius: 50%;
  bottom: calc(var(--space) * 4);
  color: var(--bgColor);
  cursor: pointer;
  line-height: calc(var(--space) * 1.8);
  height: calc(var(--space) * 2);
  position: fixed;
  text-align: center;
  right: calc(var(--space) * 1.5);
  width: calc(var(--space) * 2);
  z-index: 9999;
  @extend animate;

  &:before {
    @mixin fontAwesomeIcon f106;
    font-size: calc(var(--fontSize) * 2);
  }

  &:--enter {
    background: var(--textColor);
  }
}

.container {
  clear: fix;
  margin: 0 auto;
  width: 1200px;
}

.pageContent {
  background: var(--bgColor);
  min-height: 77vh;
  padding: calc(var(--space) * 3) 0;
}

section {
  display: block;
  lost-column: 9/12 0 0;
}

.hidden {
  visibility: hidden;
}