.marketing_strategy {
  &__title {
    margin-bottom: calc(var(--space) * 1);

    h2 {
      margin: 0;
      text-align: center;
    }

    p {
      text-align: center;
      margin: calc(var(--space) * 0.3) 0 0;

      &:after {
        background: var(--brandColor);
        content: '';
        display: block;
        height: 5px;
        margin: var(--space) auto calc(var(--space) * 2);
        width: 50px;
      }
    }
  }

  &__slogan {
    color: var(--brandColor);
    font-size: calc(var(--fontSize) * 1.2);
    font-style: italic;
    font-weight: bold;
    line-height: calc(var(--space) * 1.5);
    padding: var(--space) 0 0;
    text-align: center;
  }

  .block--deco {
    font-size: calc(var(--fontSize) * 1.1);
    line-height: calc(var(--space) * 1.5);
    height: 439px;
  }

  .slick {
    &-dots {
      bottom: calc(var(--space) * -1);
    }

    &-prev,
    &-next {
      height: 40px;
      &:before {
        color: var(--textColor);
        font-size: 40px;
      }
    }

    &-prev {
      left: calc(var(--space) * -1.5);
    }

    &-next {
      right: calc(var(--space) * -1.5);
    }

    &-slide {
      height: 300px;
      padding: var(--space) calc(var(--space) * 0.5);
    }
  }
}