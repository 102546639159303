.index {
  background: var(--bgColor);
  height: 100vh;
  overflow: hidden;

  &__word {
    text-align: center;
    z-index: 1;

    h3 {
      color: var(--bgColor);
      font-size: calc(var(--fontSize) * 2.8);
      text-shadow: var(--shadowHeavy);
      white-space: nowrap;

      @media (--screen1600) {
        font-size: calc(var(--fontSize) * 3.5);
      }
    }

    p {
      animation-delay: 0.5s;
      background: color(var(--brandColor) a(50%));
      color: var(--bgColor);
      font-size: calc(var(--fontSize) * 1.2);
      padding: calc(var(--space) * 0.5);
      @media (--screen1600) {
        font-size: calc(var(--fontSize) * 1.8);
      }
    }
  }

  iframe,
  .frame {
    border: none;
    height: 100vh;
    left: 0;
    lost-align: center;
    overflow: hidden;
    top: 0;
    width: 100vw;
  }
}