footer {
  background: var(--brandColor);
  color: color(var(--brandColor) l(+43%));
  display: block;
  font-size: calc(var(--fontSize) * 0.87);
  text-align: center;
  width: 100%;

  .&--index {
    bottom: 0;
    position: absolute;
    z-index: 1000;
  }

  a {
    color: color(var(--brandColor) l(+43%));

    &:--enter {
      color: var(--bgColor);
    }
  }

  .container {
    padding: var(--space);
  }

}