aside {
  display: block;
  lost-column: 3/12 0 0;
  padding-right: calc(var(--space) * 2);

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    display: block;
  }

  a {
    background: var(--brandColorLight);
    color: var(--textColor);
    display: block;
    font-weight: bold;
    text-indent: calc(var(--space) * -0.6);
    padding: calc(var(--space) * 0.9) calc(var(--space) * 0.7) calc(var(--space) * 0.9) calc(var(--space) * 2);

    &:--enter {
      background: color(var(--brandColorLight) l(-10%));
    }

    &:before {
      @mixin fontAwesomeIcon f114;
      margin-right: calc(var(--space) * 0.5);
    }

    &.current {
      background: var(--brandColor);
      color: var(--bgColor);
    }
  }
}