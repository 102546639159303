@define-mixin fontAwesomeIcon $icon {
  content: '\$icon';
  display: inline-block;
  @extend fontAwesome;
}

@define-mixin pseudoBlock $display:block {
  content: '';
  display: $display;
}

@define-mixin pageBanner $height:328px {
  &__banner {
    height: $height;

    &.slick-dotted.slick-slider {
      margin-bottom: 0;
    }
  }
}