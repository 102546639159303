form {
  padding: calc(var(--space) * 2) 0;
}

input,
button,
select,
textarea {
  background: var(--bgColor);
  border: var(--border);
  font-family: var(--fontFamily);
  padding: calc(var(--space) * 0.5);
  @extend animate;

  &.err {
    border-color: var(--highColor);
  }
}

input[type="button"],
button {
  background: var(--brandColor);
  border: none;
  color: var(--bgColor);

  & + & {
    margin-left: var(--space);
  }

  &:focus,
  &:hover {
    background: var(--textColor);
  }
}

input[type="text"],
input[type="password"],
input[type="email"],
textarea {
  width: 100%;

  + label {
    color: color(var(--textColor) l(+40%));
    left: calc(var(--space) * 0.5);
    position: absolute;
    top: calc(var(--space) * 0.4);
    @extend animate;
  }

  &:--enter {
    border-color: color(var(--textColor) l(+40%));
  }

  &:--enter,
  &:valid {

    + label {
      color: var(--brandColor);
      font-size: calc(var(--fontSize) * 0.8);
      left: 0;
      top: calc(var(--space) * -1);
    }
  }

  &:valid.err {
    border: var(--border);
  }
}


select {
  &.company_location {
    width: 100%;
  }
}

.form {
  &__element {
    margin-top: calc(var(--space) * 0.8);
    position: relative;
  }

  &__buttonArea {
    margin-top: calc(var(--space) * 1.5);
    text-align: center;
  }
}

.msg {
  color: var(--highColor);
  font-weight: bold;
  line-height: 4rem;
}