table {
  border-collapse: collapse;

  th,
  td {
    border: 1px solid color(var(--textColor) l(80%));
    padding: calc(var(--space) * 0.5);
  }

  tr {
    .gray {
      background: color(var(--textColor) a(10%));
    }
  }

  thead th {
    background: var(--brandColor);
    color: var(--bgColor);
  }

  tbody th {
    background: color(var(--brandColorLight) l(-10%));
  }
}