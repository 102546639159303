.cement_clinker_trading {
  @mixin pageBanner 363px;

  .block--deco {
    height: 328px;
    padding: calc(var(--space) * 1.5);
  }

  .manufacturer {
    clear: fix;
    text-indent: 0;

    &__title,
    &__content {
      float: left;
    }

    &__title {
      width: 34%;
    }

    &__content {
      font-size: calc(var(--fontSize) * 0.9);
      width: 66%;
    }
  }
}