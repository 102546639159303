.foundation {
  &__title {
    color: var(--brandColor);
    font-size: calc(var(--fontSize) * 1.1);
    font-weight: bold;
    font-style: italic;
    text-align: center;
  }
  .timeline {
    &__block {
      clear: fix;
      padding: var(--space) 0;

      &:--odd {
        background: color(var(--textColor) a(10%));
      }

      &:--even {
        background: color(var(--textColor) a(5%));
      }

      &:--enter {
        .timeline__time__end {
          transform: scale(1.2);
        }

        .timeline__word {
          color: var(--brandColor);
        }
      }
    }

    &__time,
    &__time__start,
    &__time__end,
    &__word {
      @extend animate;
    }

    &__time,
    &__word {
      float: left;
    }

    &__time {
      padding: 0 var(--space);
      position: relative;
      text-align: center;
      width: 15%;

      &__start,
      &__end {
      }

      &__start {
        color: color(var(--textColor) a(40%));
        font-weight: bold;

        &:after {
          @mixin pseudoBlock;
          background: color(var(--textColor) a(10%));
          bottom: 0;
          position: absolute;
          top: 0;
          right: 0;
          width: 2px;
        }
      }

      &__end {
        color: var(--brandColor);
        font-size: calc(var(--space) * 1.2);
        margin-top: calc(var(--space) * 0.5);
      }
    }

    &__word {
      lost-align: center;
      padding-left: var(--space);
      width: 85%;
    }
  }
}