.coal_flyash {
  @mixin pageBanner;

  &__p1 {
    margin: calc(var(--space) * 1) 0;
  }

  &__p2 {
    margin: calc(var(--space) * -0.5) 0 calc(var(--space) * -1.3);
  }
}