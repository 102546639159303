.js-gallery {
  a {
    display: block;
    overflow: hidden;
    position: relative;

    &:before {
      background: black;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      opacity: 0;
      @extend animate;
    }

    &:after {
      background: var(--bgColor);
      border-radius: 50%;
      color: var(--brandColor);
      font-size: 1.5em;
      left: 50%;
      margin-left: -1em;
      margin-top: -0.8em;
      padding: 0.58em 0.61em;
      position: absolute;
      top: 50%;
      opacity: 0;
      z-index: 15;
      transform: translateY(-100%);
      @extend animate;

      @mixin fontAwesomeIcon f002;
    }


    &:--enter {

      &:before {
        opacity: 0.8;
      }

      &:after {
        transform: translateY(0);
        opacity: 1;
      }
    }

    img {
      vertical-align: middle;
    }
  }
}