@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;

  src: url('../fonts/slick.eot');
  src: url('../fonts/slick.eot?#iefix') format('embedded-opentype'), url('../fonts/slick.woff') format('woff'), url('../fonts/slick.ttf') format('truetype'), url('../fonts/slick.svg#slick') format('svg');
}

.slick {
  &-slider {
    .slick-arrow,
    .slick-dots {
      opacity: 0.7;
    }

    &:--enter {
      .slick-arrow,
      .slick-dots {
        opacity: 1;
      }
    }
  }

  &-prev,
  &-next {
    height: 80px;
    width: 40px;

    &:before {
      font-size: 80px;
    }
  }

  &-prev {
    left: var(--space);
    z-index: 1;

    &:before {
      @mixin fontAwesomeIcon f104;
    }
  }

  &-next {
    right: var(--space);

    &:before {
      @mixin fontAwesomeIcon f105;
    }
  }

  &-dots {
    bottom: calc(var(--space) * 0.5);

    li {
      &.slick-active button:before {
        color: var(--brandColor);
      }

      button:before {
        color: var(--textColor);
      }
    }
  }
}