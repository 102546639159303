nav {
  background: color(var(--bgColor) a(90%));
  position: relative;
  top: 0;
  width: 100%;
  z-index: 1000;

  .&--index {
    position: absolute;
  }

  * {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .container > ul > li > a {
    color: var(--brandColor);
    font-weight: bold;
    text-transform: uppercase;
    padding: calc(var(--space) * 1.3) var(--space);
    border-top: 5px solid transparent;

    &.current {
      border-color: var(--brandColor);
    }

    &.highlighted,
    &:--enter {
      background: var(--brandColor);
      color: var(--bgColor);
    }
  }

  .logo {
    float: left;
    img {
      width: 100%;
    }
  }
}

.navi {
  float: right;

  ul {
    background: color(var(--bgColor) a(90%));
    border-top: 5px solid var(--brandColor);
    box-shadow: var(--shadow);
    clear: fix;
    position: absolute;
    width: 20em;
  }

  > li {
    float: left;
  }


  .sub-arrow {
    display: none;
  }

  a {
    color: var(--textColor);
    line-height: var(--space);
    padding: calc(var(--space) * 0.5) var(--space);

    &:--enter {
      color: var(--brandColor);
    }
  }
}